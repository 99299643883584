import { request } from '../BASE_REQUEST';
import { BASE_JOURNEY_URL } from '../../api';

export const uploadJourneyFile = async (file, weekday) => {
    const formData = new FormData();
    formData.append('file', file);
    formData.append('weekday', weekday || '');

    try {
        const response = await request({
            url: `${BASE_JOURNEY_URL}/journey/journeys/divzero/start/`,
            method: 'POST',
            data: formData,
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        });

        return response;
    } catch (error) {
        return Promise.reject(error);
    }
};
