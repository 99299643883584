import React, { useState } from 'react';
import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Button,
    TextField,
    CircularProgress,
} from '@mui/material';
import { useSnackbar } from 'notistack';
import { uploadJourneyFile } from '../../utils/api/POST/uploadJourneyFile';

const FileUploadDialog = ({ open, onClose, onUploadSuccess }) => {
    const [file, setFile] = useState(null);
    const [weekday, setWeekday] = useState(null); // Novo estado para weekday
    const [loading, setLoading] = useState(false);
    const { enqueueSnackbar } = useSnackbar();

    const handleFileChange = (event) => {
        setFile(event.target.files[0]);
    };

    const handleUpload = async () => {
        if (!file) {
            enqueueSnackbar('Selecione um arquivo antes de enviar.', {
                variant: 'warning',
                autoHideDuration: 2000,
            });
            return;
        }

        setLoading(true);

        try {
            const response = await uploadJourneyFile(file, weekday);
            enqueueSnackbar('Arquivo enviado com sucesso!', {
                variant: 'success',
                autoHideDuration: 2000,
            });
            onUploadSuccess(); // Sucesso no upload
            onClose();
        } catch (error) {
            enqueueSnackbar('Erro ao enviar o arquivo. Tente novamente.', {
                variant: 'error',
                autoHideDuration: 2000,
            });
        } finally {
            setLoading(false);
        }
    };

    return (
        <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
            <DialogTitle>Enviar Novo Arquivo</DialogTitle>
            <DialogContent>
                <TextField
                    type="file"
                    onChange={handleFileChange}
                    fullWidth
                    InputLabelProps={{ shrink: true }}
                    disabled={loading}
                />
                <TextField
                    label="Weekday"
                    type="number"
                    inputProps={{ min: 2, max: 6 }}
                    value={weekday || ''}
                    onChange={(e) => setWeekday(e.target.value ? parseInt(e.target.value, 10) : null)}
                    fullWidth
                    margin="normal"
                    disabled={loading}
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} disabled={loading}>
                    Cancelar
                </Button>
                <Button
                    onClick={handleUpload}
                    variant="contained"
                    color="primary"
                    disabled={loading}
                >
                    {loading ? <CircularProgress size={24} /> : 'Enviar'}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default FileUploadDialog;