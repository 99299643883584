import { AxiosResponse } from 'axios';
import { request } from '../BASE_REQUEST';
import { createCampaignProps } from '../interfaces';
import { BASE_RCS_URL } from '../../api';

export const createCampaing = async (
  fields: createCampaignProps,
  url: string,
  file: File
): Promise<AxiosResponse<any> | undefined> => {
  if (!fields.start_hour || !fields.end_hour || !fields.schedule_date) {
    delete fields.start_hour;
    delete fields.end_hour;
    delete fields.schedule_date;
  }

  if (!fields.id_ai_user_layout) {
    delete fields.id_ai_user_layout;
  }

  delete fields.campaign_type;

  const response = await request({ url, data: fields, method: 'POST' });
  const UPLOAD_URL = response.data.upload_url;
  if (UPLOAD_URL) {
    return await createImage(file, UPLOAD_URL);
  } else {
    return response;
  }
};

export const createImage = async (
  file: File,
  url: string
): Promise<AxiosResponse<any>> => {
  const response = await request({
    method: 'PUT',
    url,
    data: file,
    headers: {
      'Content-Type': 'text/csv',
    },
  });

  return response;
};

export const uploadRichCardFile = async (
  file: File
): Promise<AxiosResponse<any>> => {
  const response = await request({
    method: 'POST',
    url: `${BASE_RCS_URL}/rcs/aws-upload-file/`,
    data: { filename: file.name },
    headers: { 'Content-Type': 'application/json' },
  });

  await request({
    method: 'PUT',
    url: response.data.upload_url,
    data: file,
    headers: { 'Content-Type': file.type },
  });

  return response.data.file_url;
};
